import { AfterContentInit, Component, Injector, OnDestroy, OnInit, Provider, ViewChild } from "@angular/core";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { HubCorporateService } from "../../shared/services/hub-corporate.service";
import { map } from "rxjs/operators";
import { ProfilesService } from "../../shared/services/profiles.service";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { LocalStorageService } from "../../shared/services/local-storage.service";
import { HelpersService } from "../../shared/services/helpers.service";
import { NDCApiService } from "../../shared/services/ndc-api.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Dictionary } from "../../shared/types/dictionary";
import { NgbDateParserFormatter, NgbDateStruct, NgbDropdown, NgbTabset } from "@ng-bootstrap/ng-bootstrap";
import { HttpParams } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { AirGatewayDateFormatter } from "../../shared/services/air-gateway-date-formatter.service";
import { ORDER_STATUS } from "../../shared/constants";
import { MetaService } from "../../shared/services/meta.service";
import moment from "moment";
import { OrderListTabId } from "../../shared/types/order";
import { collapseAnimation, rotateAnimation } from "angular-animations";
import { NotificationService } from "../../shared/services/notification.service";

@Component({
  selector: "app-orders",
  templateUrl: "./order-list.component.html",
  styleUrls: ["./order-list.component.scss"],
  providers: [
    <Provider>{
      provide: NgbDateParserFormatter,
      useClass: AirGatewayDateFormatter,
    },
  ],
  animations: [
    rotateAnimation({ degrees: -180, duration: 500 }),
    collapseAnimation({ duration: 400 }),
  ],
})
export class OrderListComponent implements OnInit, AfterContentInit, OnDestroy {
  @ViewChild("orderListTabs", { static: true }) tabset: NgbTabset;

  orders = [];
  showSearch = false;
  showLoader = false;
  orderListError: any = {};
  warningMessage = [];
  hasChanges = false;

  form: FormGroup;
  openById = {};

  defaultProvider = "";
  defaultSort = "booking_date_desc";

  // pagination
  page = 1;
  pageSize = 10;
  totalCount = 0;
  today;
  TabId = OrderListTabId;
  selectedTab = "all";
  prevTabId = null;
  tabInitialised = false;
  showSubagencies = false;
  agencies = [];
  selectedAgent = "all";
  ORDER_STATUS = ORDER_STATUS;

  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  corporatePlaceholder = "Corporate";
  corporateSelectData: any[] = [];
  corporateResponseData;

  activeStatuses = [
    ORDER_STATUS.PENDING,
    ORDER_STATUS.TICKETED,
    ORDER_STATUS.STARTED,
  ];

  sortOptions = [
    { sort: "booking_date_desc", label: "Booking Date" },
    { sort: "departure_date_desc", label: "Departure Date" },
    {
      sort: "departure_date_asc",
      label: "Departure Date ASC",
      statuses: this.activeStatuses,
    },
    {
      sort: "payment_time_limit_asc",
      label: "Expiration Time",
      statuses: this.activeStatuses,
    },
  ];

  sortSelected = "booking_date_desc";
  counters: any = {};
  corporateID: any;
  selectedCorporate: any;
  isFilteredCorporate = true;
  airports = Dictionary.getAirports(false);
  showOriginData: any[] = [];
  showDestinationData: any[] = [];
  searchTypeOptions = Dictionary.getSearchTypeOptions();
  dbService: NgxIndexedDBService;

  defaultFormValues = {
    id: "",
    pnr: "",
    status: "",
    origin: "",
    destination: "",
    departureDate: "",
    operatingCarrier: "",
    psgName: "",
    psgSurname: "",
    showOrdersType: "all",
    provider: this.defaultProvider,
    sort: this.defaultSort,
    showSubagencies: "",
    ticketNumber: "",
    agency: "",
    corporateID: "",
    bookingDateRange: "",
    issuingDateRange: "",
    bookingType: "",
  };

  emailForm: FormGroup;
  recipientEmails = [];
  selectedRecipientEmails = [];

  constructor(
    public helpers: HelpersService,
    public service: NDCApiService,
    public meta: MetaService,
    public fb: FormBuilder,
    public ls: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private profilesService: ProfilesService,
    private hubCorporateService: HubCorporateService,
    private _notificationSvc: NotificationService,
    private injector: Injector
  ) {
    if (!this.helpers.isPrivateMode) {
      this.dbService = <NgxIndexedDBService>(
        this.injector.get(NgxIndexedDBService)
      );
    }
  }

  ngOnInit() {
    this.today = new Date().getTime();

    this.buildForm();
    this.buildEmailForm();
    this.parseUrl();
    this.detectUnsavedChanges();

    this.showSubagencies =
      this.form.controls["showSubagencies"].value === "true";
    this.showSearch = this.selectedTab === this.TabId.Search;

    this.removePastFlights();

    if (this.form.controls["corporateID"].value) {
      this.hubCorporateService
        .get(this.form.controls["corporateID"].value)
        .pipe(
          map((data: any) => data.body),
          map((data: any) => {
            if (data.id === this.form.controls["corporateID"].value) {
              return data;
            }
          }),
          take(1)
        )
        .subscribe((res) => {
          this.selectedCorporate = res.name;
          this.corporateID = res.id;
          this.isFilteredCorporate = false;
        });
    }

    this.search();
    this.removeOldOrdersFromIndexDb();
  }

  ngAfterContentInit(): void {
    if (!this.tabInitialised) {
      setTimeout(() => {
        this.tabset.select(this.selectedTab);
      }, 250);
      this.tabInitialised = true;
    }
  }

  search() {
    this.orderListError = {};
    this.warningMessage = [];
    this.showLoader = true;
    this.orders = [];
    this.buildUrl();

    let params = this.prepareParams();

    this.service
      .sendOrderList(params)
      .then((response: any) => {
        this.showLoader = false;
        if (!response) {
          return;
        }

        this.warningMessage = this.helpers.getWarningsFromResponse(response);

        if (response.orders) {
          this.orders = response.orders;
        }
        this.agencies =
          response.agencies && response.agencies.length
            ? response.agencies.sort((a, b) => {
                return a > b ? 1 : -1;
              })
            : [];

        if (this.showSubagencies) {
          this.profilesService.allAgencies = this.agencies;
        }

        this.totalCount = response.count;
        this.counters = response.counters;
      })
      .catch((response) => {
        this.showLoader = false;
        this.orderListError = this.helpers.getError(response);
        if (this.helpers.isCriticalError(response)) {
          throw response;
        }
      });
  }

  private buildForm() {
    this.form = this.fb.group(this.defaultFormValues);
  }

  parseUrl() {
    let params = this.route.snapshot.queryParams;

    this.form.controls["id"].setValue(params.id || "");
    this.form.controls["pnr"].setValue(params.pnr || "");
    this.form.controls["status"].setValue(params.status || "");
    this.form.controls["origin"].setValue(params.origin || "");
    this.form.controls["destination"].setValue(params.destination || "");
    this.form.controls["psgName"].setValue(params.psgName || "");
    this.form.controls["psgSurname"].setValue(params.psgSurname || "");
    this.form.controls["provider"].setValue(
      params.providers || this.defaultProvider
    );
    this.form.controls["sort"].setValue(params.sort || this.defaultSort);
    this.form.controls["showSubagencies"].setValue(
      params.showSubagencies || ""
    );
    this.form.controls["bookingType"].setValue(params.bookingType || "");
    this.form.controls["operatingCarrier"].setValue(
      params.operatingCarrier || ""
    );
    this.selectedAgent = params.showOrdersType || "all";

    let depDate = params.departureDate || "";
    let depDateObj: NgbDateStruct | string = "";
    let ticketNumber = params.ticketNumber || "";
    let agency = "";
    if (params.agency) {
      agency = this.profilesService.selectedAgency = params.agency;
    }
    let corporateID = params.corporateID || "";

    if (depDate) {
      let p = depDate.split("-");
      depDateObj = <NgbDateStruct>{
        year: parseInt(p[0]),
        month: parseInt(p[1]),
        day: parseInt(p[2]),
      };
    }

    if (params.bookingDateFrom || params.bookingDateTo) {
      this.form.controls["bookingDateRange"].setValue(
        params.bookingDateFrom + "/" + params.bookingDateTo
      );
    }

    if (params.issuingDateFrom || params.issuingDateTo) {
      this.form.controls["issuingDateRange"].setValue(
        params.issuingDateFrom + "/" + params.issuingDateTo
      );
    }

    if (corporateID) {
      this.form.controls["corporateID"].setValue(corporateID);
    }

    this.form.controls["departureDate"].setValue(depDateObj);
    this.form.controls["ticketNumber"].setValue(ticketNumber);
    this.form.controls["agency"].setValue(agency);
    this.page = params.page || 1;
    this.pageSize = params.pageSize || 10;
    this.selectedTab = params.tab || "all";
  }

  buildUrl() {
    let params = this.prepareURLParams();
    this.router.navigate([], { queryParams: params });
  }

  buildParams(values, additionalParams = {}) {
    const [fromDate, toDate] = this.formatDateRange(values.bookingDateRange);
    const [fromIssuingDate, toIssuingDate] = this.formatDateRange(
      values.issuingDateRange
    );

    const paramMap = {
      id: values.id,
      pnr: values.pnr,
      notified: values.status === "Notified" ? "true" : undefined,
      status: values.status !== "Notified" ? values.status : undefined,
      origin: values.origin,
      destination: values.destination,
      departureDate: HelpersService.getFormattedDate(values.departureDate),
      operatingCarrier: values.operatingCarrier,
      ticketNumber: values.ticketNumber,
      agency: values.agency,
      psgName: values.psgName,
      psgSurname: values.psgSurname,
      sort: values.sort,
      providers: values.provider,
      showSubagencies: values.showSubagencies,
      bookingDateFrom: fromDate,
      bookingDateTo: toDate,
      issuingDateFrom: fromIssuingDate,
      issuingDateTo: toIssuingDate,
      corporateID: this.corporateID,
      bookingType: values.bookingType,
      showOrdersType: this.selectedAgent,
      ...additionalParams,
    };

    return paramMap;
  }

  extractFormValues(controls) {
    return {
      id: controls["id"].value,
      pnr: controls["pnr"].value,
      status: controls["status"].value,
      origin: controls["origin"].value,
      destination: controls["destination"].value,
      departureDate: controls["departureDate"].value,
      operatingCarrier: controls["operatingCarrier"].value,
      ticketNumber: controls["ticketNumber"].value,
      agency: controls["agency"].value,
      psgName: controls["psgName"].value,
      psgSurname: controls["psgSurname"].value,
      showOrdersType: controls["showOrdersType"].value,
      sort: this.sortSelected ? this.sortSelected : controls["sort"].value,
      provider: controls["provider"].value,
      showSubagencies: controls["showSubagencies"].value,
      bookingType: controls["bookingType"].value,
      bookingDateRange: controls["bookingDateRange"].value,
      issuingDateRange: controls["issuingDateRange"].value,
    };
  }

  formatDateRange(dateRange) {
    if (!dateRange) {
      return ["", ""];
    }
    const [fromDate, toDate] = dateRange.split("/");
    const formatPart = (part) =>
      part
        .split("-")
        .map((item) => (item.length === 1 ? item.padStart(2, "0") : item))
        .join("-");
    return [formatPart(fromDate), formatPart(toDate)];
  }

  prepareParams() {
    const controls = this.form.controls;
    const values = this.extractFormValues(controls);
    const additionalParams = {
      page: this.page?.toString(),
      pageSize: this.pageSize?.toString(),
    };

    let params = new HttpParams();
    const paramMap = this.buildParams(values, additionalParams);

    Object.keys(paramMap).forEach((key) => {
      if (paramMap[key]) {
        params = params.set(key, paramMap[key]);
      }
    });

    return params;
  }

  prepareURLParams(excludePaginationParams = false) {
    const controls = this.form.controls;
    const values = this.extractFormValues(controls);
    const additionalParams = {
      page: !excludePaginationParams ? this.page?.toString() : undefined,
      pageSize: this.pageSize.toString(),
      tab: !excludePaginationParams ? this.selectedTab || "all" : undefined,
    };

    const paramMap = this.buildParams(values, additionalParams);
    let params = {};

    Object.keys(paramMap).forEach((key) => {
      if (paramMap[key]) {
        params[key] = paramMap[key];
      }
    });

    return params;
  }

  clearSearch() {
    let showSubagencies = this.form.controls["showSubagencies"].value;
    this.buildForm();
    this.form.controls["showSubagencies"].setValue(showSubagencies);
    this.hasChanges = false;
    this.detectUnsavedChanges();
  }

  getDepartureRemaining(order: any) {
    let departure = new Date(order.flights[0].departure.date);
    let dayDiff =
      Math.floor((departure.getTime() - this.today) / 1000 / 60 / 60 / 24) + 1;
    return dayDiff;
  }

  onTabSelected(nextId: string = "all") {
    this.page = 1;
    this.selectedTab = nextId;
    this.showSearch = this.selectedTab === this.TabId.Search;
    this.sortSelected = "booking_date_desc";
    switch (this.selectedTab) {
      case "search":
        if (this.prevTabId) {
          this.form.controls["status"].setValue("");
        }
        this.helpers.scrollTo(700, 100);
        break;
      case "all":
        this.clearSearch();
        break;
      default:
        this.clearSearch();
        this.form.controls["status"].setValue(this.selectedTab);
    }
    this.prevTabId = nextId;
    this.search();
    this.buildUrl();
  }

  changeSort($event) {
    this.sortSelected = $event.target.value;
    this.page = 1;
    this.search();
  }

  onChangeAgency(agency: string) {
    this.profilesService.selectedAgency = agency;
  }

  showOrder(order, event) {
    let urlParams = "?";
    urlParams +=
      this.showSubagencies && this.agencies && this.agencies.length
        ? "from_subagencies=true"
        : "";
    this.meta.resetMeta();
    const url = `/orders/${order.id}${urlParams}`;
    if (event.ctrlKey || event.metaKey) {
      window.open(url, "_blank");
    } else {
      this.helpers.go(url);
    }
  }

  onFilterCorporate(event) {
    if (event) {
      this.isFilteredCorporate = false;
      this.corporateID = event.id;
      this.selectedCorporate = event.text;
    } else {
      this.isFilteredCorporate = true;
      this.corporateID = null;
      this.selectedCorporate = null;
    }

    this.search();
  }

  removePastFlights() {
    let warnTabsInfoTemp = this.ls.orderWarnTabsInfo.filter((order) => {
      return !moment(order.last_arrival).isBefore(moment().subtract(1, "days"));
    });
    this.ls.orderWarnTabsInfo = warnTabsInfoTemp;
  }

  onSelectValueChanged(controlName: string, value: string) {
    if (value) {
      this.form.controls[controlName].setValue(value.toUpperCase());
    } else {
      this.form.controls[controlName].setValue("");
    }
  }

  onSearchInput(controlName: string, event) {
    if (!event) {
      this.showOriginData = [];
      this.showDestinationData = [];
      return;
    }
    if (controlName === "origin") {
      this.showOriginData = this.airports.filter((item) =>
        item.text.toLowerCase().startsWith(event.toLowerCase())
      );
    } else if (controlName === "destination") {
      this.showDestinationData = this.airports.filter((item) =>
        item.text.toLowerCase().startsWith(event.toLowerCase())
      );
    } else {
      this.showOriginData = [];
      this.showDestinationData = [];
    }
  }

  resetBookingDateRange(value: string) {
    this.form.controls["bookingDateRange"].setValue(value);
  }

  resetIssuingDateRange(value: string) {
    this.form.controls["issuingDateRange"].setValue(value);
  }

  toggleSearch() {
    this.showSearch = !this.showSearch;
  }

  buildEmailForm() {
    this.emailForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
    });
    this.setPassengerEmails();
  }

  setPassengerEmails() {
    this.recipientEmails = [];
    this.selectedRecipientEmails = [];
    if (this.ls.email) {
      this.recipientEmails.push(this.ls.email);
    }
  }

  addEmail() {
    const email = this.emailForm.get("email").value;
    this.recipientEmails.push(email);
    this.selectedRecipientEmails.push(email);
    this.emailForm.reset();
  }

  onPassengerEmailsChange(email: string) {
    if (!this.selectedRecipientEmails.includes(email)) {
      this.selectedRecipientEmails.push(email);
    } else {
      this.selectedRecipientEmails.splice(
        this.selectedRecipientEmails.indexOf(email),
        1
      );
    }
  }

  export(dropdown: NgbDropdown) {
    let body: any = this.prepareURLParams(true);
    body.recipientEmails = this.selectedRecipientEmails;
    this.service.sendOrderExport(body).then((response: any) => {
      this._notificationSvc.success("", "Your request has been processed");
      dropdown.close();
    });
  }

  detectUnsavedChanges() {
    this.hasChanges =
      JSON.stringify(this.form.value) !==
      JSON.stringify(this.defaultFormValues);
    this.form.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((currentValue) => {
        this.hasChanges =
          JSON.stringify(currentValue) !==
          JSON.stringify(this.defaultFormValues);
      });
  }

  removeOldOrdersFromIndexDb() {
    if (!this.ls.currentDate) {
      this.ls.currentDate = new Date().getDate();
    }
    if (
      +this.ls.currentDate !== +new Date().getDate() &&
      !this.helpers.isPrivateMode
    ) {
      this.dbService.getAll("order_warn_tabs_info").subscribe((storeData) => {
        storeData.map((order) => {
          let currentDate = new Date();
          let orderDate = new Date(order["last_arrival"]);
          let differenceBetweenDates = Math.floor(
            (Date.UTC(
              orderDate.getFullYear(),
              orderDate.getMonth(),
              orderDate.getDate()
            ) -
              Date.UTC(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate()
              )) /
              (1000 * 60 * 60 * 24)
          );
          if (!differenceBetweenDates || differenceBetweenDates < -30) {
            this.dbService
              .delete("order_warn_tabs_info", order["id"])
              .subscribe((storeData) => {});
          }
        });
      });
      this.ls.currentDate = new Date().getDate();
    }
  }

  isConsultant() {
    return this.ls.role === "consultant";
  }

  ngOnDestroy() {
    this.profilesService.selectedAgency = "";
    this.profilesService.allAgencies = [];
  }
}
