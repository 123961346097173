import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NDCApiService } from '../../shared/services/ndc-api.service';
import { Process } from '../../shared/interfaces/show-process';
import { OrderSplitData, Traveler } from '../../shared/models/order-split-data';
import { ErrorTypes } from '../../shared/types/dictionary';
import { NotificationService } from '../../shared/services/notification.service';
import {HelpersService} from "../../shared/services/helpers.service";
import {SentryService} from "../../shared/services/sentry.service";

@Component({
  selector: 'app-order-split-modal',
  templateUrl: './order-split-modal.component.html',
  styleUrls: ['./order-split-modal.component.scss']
})
export class OrderSplitModalComponent implements OnInit {

  @Input() order;
  @Input() owner;
  @Output() emitClose = new EventEmitter();
  @Output() emitDismiss = new EventEmitter();
  @Output() emitOrderSplitSuccess = new EventEmitter();

  orderSplitData: OrderSplitData;
  travelers: Traveler[];
  leftSideTravelers: Traveler[];
  rightSideTravelers: Traveler[];
  leftSideTransitPassengerList: Traveler[] = [];
  rightSideTransitPassengerList: Traveler[] = [];
  orderSplitingProcess: Process = {
    isProcess: false,
    processTitle: "Order spliting..."
  };
  orderSplitError:any = {};

  constructor(
    public service: NDCApiService,
    protected _notificationSvc: NotificationService,
    public helpers: HelpersService,
    public sentryService: SentryService
    ) { }

  ngOnInit(): void {
    this.setOrderSplitData();
  }

  close() {
    this.emitClose.emit();
  }

  setOrderSplitData() {
    this.orderSplitData = {
      id: this.order.id,
      action: 'split_order',
      passengers: []
    };

    this.travelers = this.order.passengers.map((passenger) => {
      return {
        travelerReference: passenger.travelerReference,
        name: passenger.data.name + ' ' + passenger.data.surname,
        passengerType: passenger.passengerType,
        infantReference: passenger.infantReference
      };
    });

    this.leftSideTravelers = this.travelers;
    this.rightSideTravelers = [];
  }

  getInfantRefenencePassenger(infantReference: string) {
    return this.travelers.find(tr => tr.travelerReference === infantReference);
  }

  onChangeLeftTransitList(traveler: Traveler, flag: boolean) {
    if (flag) {
      this.leftSideTransitPassengerList.push(traveler);
    } else {
      const index = this.leftSideTransitPassengerList.findIndex(tr => tr.travelerReference === traveler.travelerReference);
      this.leftSideTransitPassengerList.splice(index, 1);
    }
  }

  onChangeRightTransitList(traveler: Traveler, flag: boolean) {
    if (flag) {
      this.rightSideTransitPassengerList.push(traveler);
    } else {
      const index = this.rightSideTransitPassengerList.findIndex(tr => tr.travelerReference === traveler.travelerReference);
      this.rightSideTransitPassengerList.splice(index, 1);
    }
  }

  moveToRight() {
    this.rightSideTravelers = [...this.rightSideTravelers, ...this.leftSideTransitPassengerList];
    this.leftSideTravelers = this.leftSideTravelers.filter(traveler => {
      return this.leftSideTransitPassengerList.findIndex(leftTraveler => leftTraveler.travelerReference === traveler.travelerReference) < 0 &&
        this.leftSideTransitPassengerList.findIndex(leftTraveler => leftTraveler.infantReference === traveler.travelerReference) < 0;
    });
    this.leftSideTransitPassengerList = [];
  }

  moveToLeft() {
    this.leftSideTravelers = [...this.leftSideTravelers, ...this.rightSideTransitPassengerList];
    this.rightSideTravelers = this.rightSideTravelers.filter(traveler => {
      return this.rightSideTransitPassengerList.findIndex(leftTraveler => leftTraveler.travelerReference === traveler.travelerReference) < 0;
    });
    this.rightSideTransitPassengerList = [];
  }

  sendOrderSplit() {
    const infantPassengers = [];
    this.orderSplitData.passengers = this.rightSideTravelers.map(traveler => {
      if (traveler.infantReference) {

        const infantPassenger = this.order.passengers.find(passenger => passenger.travelerReference === traveler.infantReference);
        if (infantPassenger) {
          infantPassengers.push(infantPassenger);
        }
      }
      return this.order.passengers.find(passenger => passenger.travelerReference === traveler.travelerReference);
    });
    this.orderSplitData.passengers = [...this.orderSplitData.passengers, ...infantPassengers];
    this.orderSplitingProcess.isProcess = true;

    this.orderSplitData.passengers = this.orderSplitData.passengers?.map(passenger => {
      delete passenger.document;
      delete passenger.documents;
      return passenger;
    });

    this.service.sendOrderChange(this.orderSplitData, false).then((res) => {
      this.orderSplitingProcess.isProcess = false;
      this._notificationSvc.success("Order Split", "order was successfully split", 4000);
      this.emitOrderSplitSuccess.emit(true);
    }).catch(res => {
      this.orderSplitingProcess.isProcess = false;
      this.orderSplitError = this.helpers.getError(res);
      this.sentryService.setAdditionalData(this.service.lastSessionID, this.service.lastRequestID, this.orderSplitData, res.status, res);
      if (this.helpers.isCriticalError(res)) {
        throw res;
      }
    });
  }
}
