<div class="row pb-5">
  <div class="col-lg-12">
    <div class="d-flex align-items-center justify-content-between mb-4">
      <div class="d-flex align-items-center gap-10">
        <span class="material-symbols-outlined blue-color">
          alarm
        </span>
        <h3 class="mb-0 h3-label">Time Limits</h3>
      </div>
      <div class="expand-icon-wrapper" (click)="isTTLOpened = !isTTLOpened">
        <span class="material-symbols-outlined" [@rotate]="!isTTLOpened">
          expand_less
        </span>
      </div>
    </div>
    <div class="row" [@collapse]="!isTTLOpened">
      <div class="col">
        <div class="table-responsive">
          <table class="table table-default">
            <thead>
            <tr>
              <th class="text-center"
                  [class.expired]="order.priceGuaranteeTimeLimit && (order.priceGuaranteeTimeLimit | momentTime) < (currentDate | momentTime)">
                Price Expiration
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <div class="d-flex align-items-center justify-content-center gap-5">
                  <i
                    *ngIf="order.priceGuaranteeTimeLimit"
                    [ngbPopover]="priceGuaranteeTimeLimitCountdown"
                    [autoClose]="'outside'"
                    placement="bottom"
                    class="material-icons fs-22 price-guarantee-info-icon"
                    container="body"
                    #priceGuaranteeTimeLimitPopover="ngbPopover"
                    (mouseenter)="onMouseEnter('priceExpiration')"
                    (mouseleave)="onMouseLeave('priceExpiration', priceGuaranteeTimeLimitPopover)"
                  >
                    {{buttonName.priceExpiration}}
                  </i>
                  <div class="fs-20"
                       [class.price-guarantee-limit]="order.priceGuaranteeTimeLimit"
                       [class.dark-red-color]="order.priceGuaranteeTimeLimit && (order.priceGuaranteeTimeLimit | momentTime) < (currentDate | momentTime)"
                       (mouseenter)="onMouseEnter('priceExpiration')"
                       (mouseleave)="onMouseLeave('priceExpiration', priceGuaranteeTimeLimitPopover)"
                       (click)="priceGuaranteeTimeLimitPopover.open()"
                       [ngbPopover]="((order.priceGuaranteeTimeLimit | dateFormat: false : 'HH:mm') | async) + ' UTC'"
                       [disablePopover]="!order.priceGuaranteeTimeLimit"
                       popoverClass="popover-wp"
                       container="body"
                       [triggers]="'hover'">
                    {{ order.priceGuaranteeTimeLimit ? ((order.priceGuaranteeTimeLimit | dateFormat: true : 'HH:mm') | async) : 'N/A' }}
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col">
        <div class="table-responsive">
          <table class="table table-default">
            <thead>
            <tr>
              <th class="text-center"
                  [class.expired]="order.paymentTimeLimit && (order.paymentTimeLimit | momentTime) < (currentDate | momentTime)">
                Order Expiration
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <div class="d-flex align-items-center justify-content-center gap-5">
                  <i
                    *ngIf="order.paymentTimeLimit"
                    [ngbPopover]="paymentTimeLimitCountdown"
                    [autoClose]="'outside'"
                    placement="bottom"
                    class="material-icons fs-22 payment-info-icon"
                    container="body"
                    #paymentTimeLimitPopover="ngbPopover"
                    (mouseenter)="onMouseEnter('orderExpiration')"
                    (mouseleave)="onMouseLeave('orderExpiration', paymentTimeLimitPopover)"
                  >
                    {{buttonName.orderExpiration}}
                  </i>
                  <div class="fs-20"
                       [class.payment-limit]="order.paymentTimeLimit"
                       [class.dark-red-color]="order.paymentTimeLimit && (order.paymentTimeLimit | momentTime) < (currentDate | momentTime)"
                       (mouseenter)="onMouseEnter('orderExpiration')"
                       (mouseleave)="onMouseLeave('orderExpiration', paymentTimeLimitPopover)"
                       (click)="paymentTimeLimitPopover.open()"
                       [ngbPopover]="((order.paymentTimeLimit | dateFormat: false : 'HH:mm') | async) + ' UTC'"
                       [disablePopover]="!order.paymentTimeLimit"
                       popoverClass="popover-wp"
                       container="body"
                       [triggers]="'hover'">
                    {{ order.paymentTimeLimit ? ((order.paymentTimeLimit | dateFormat: true : 'HH:mm') | async) : 'N/A' }}
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #paymentTimeLimitCountdown>
  <app-countdown
    [limit]="order.paymentTimeLimit"
  ></app-countdown>
</ng-template>

<ng-template #priceGuaranteeTimeLimitCountdown>
  <app-countdown
    [limit]="order.priceGuaranteeTimeLimit"
    [label]="'Price expires in'"
    [onExpiredMessage]="'The booking price is no longer guaranteed. Please use the issuing button to reprice.'"
  ></app-countdown>
</ng-template>
